import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const InvestForm = ({ initialValues }) => {
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'investment-form', ...values }),
        })
          .then(() => {
            // actions.resetForm();
            actions.setStatus(true);
          })
          .finally(() => actions.setSubmitting(false));
      }}
      validate={(values) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if (!values["if-firstname"]) {
          errors["if-firstname"] = 'First Name Required';
        }
        if (!values["if-lastname"]) {
          errors["if-lastname"] = 'Last Name Required';
        }
        if (!values["if-email"] || !emailRegex.test(values["if-email"])) {
          errors["if-email"] = 'Valid Email Required';
        }
        if (!values["if-confirm"]) {
          errors["if-confirm"] = 'Please confirm subscription';
        }
        if (!values["if-messenger"]) {
          errors["if-messenger"] = 'Handle required';
        }
        return errors;
      }}
    >
      {({ isSubmitting, status }) =>
        !status ? (
          <Form className="form" name="investment-form" id="investment-form" data-netlify={true}>
            <div className="form-wrapper row">
              <div className="col-lg-12 text-center mb-lg-4">
                <h4 className="mb-1">INVEST AND SUPPORT RecoveryLabs</h4>
                <p className="mb-4 mb-lg-0">
                  Wether investment or open-source grant. We want to hear from you!
                </p>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`if-firstname`}>
                    First Name <span className="text-important">*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="if-firstname"
                    id="if-firstname"
                    placeholder="Nick"
                  />
                  <ErrorMessage
                    name="if-firstname"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`if-lastname`}>
                    Last Name <span className="text-important">*</span>
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="if-lastname"
                    id="if-lastname"
                    placeholder="Gomes"
                  />
                  <ErrorMessage
                    name="if-lastname"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`if-email`}>
                    Email <span className="text-important">*</span>{' '}
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="if-email"
                    id="if-email"
                    placeholder="example@gmail.com"
                  />
                  <ErrorMessage
                    name="if-email"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor={`if-messenger`}>
                    Messenger <span className="text-important">*</span>{' '}
                  </label>
                  <Field
                    className="input"
                    type="text"
                    name="if-messenger"
                    id="if-messenger"
                    placeholder="+12345678901 or t.me/example"
                  />
                  <ErrorMessage
                    name="if-messenger"
                    render={(msg) => <div className="form-error">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 mt-2">
                <div className="form-group">
                  <div className="d-flex align-items-center justify-content-center">
                    <Field
                      className="input"
                      type="checkbox"
                      name="if-confirm"
                      id="if-confirm"
                    />
                    <label className="my-0 mx-1" htmlFor={`if-confirm`}>
                      I agree to be contacted by RecoveryLabs.
                    </label>
                  </div>
                  <ErrorMessage
                    name="if-confirm"
                    render={(msg) => <div className="form-error m-below">{msg}</div>}
                  />
                </div>
              </div>
              <div className="col-lg-6 offset-lg-3 mt-4">
                <div className="form-group">
                  <button
                    className="button primary large cta w-100"
                    type="submit"
                  >
                    CONNECT WITH US
                  </button>
                </div>
              </div>
            </div>
          </Form>
        ) : (
          <div className="plug">
            <svg
              width={48}
              height={48}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                fill="#5cc0c9"
                d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"
              />
            </svg>
            <p>Thanks for subscription! We will contact you soon!</p>
          </div>
        )
      }
    </Formik>
  );
};

export default InvestForm;
