import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import MediaElement from '../components/MediaElement';

import logo from '../images/logo.svg';
import marker from '../images/marker.svg';
import email from '../images/email.svg';
import lock from '../images/lock.svg';
import linkedin from '../images/linkedin.svg';
import twitter from '../images/twitter.svg';
import dmitry from '../images/dmitry.jpeg';
// import josh from '../images/josh.jpeg';
// import thomas from '../images/thomas.jpeg';

import Seo from '../components/seo';
import InvestForm from '../components/InvestForm';
import ClientOnly from '../components/ClientOnly';
import '../styles/main.scss';

// markup
const IndexPage = () => {
  return (
    <>
      <Seo />
      <main>
        <div className="page-section py-5 py-md-10 gray">
          <div className="container">
            <div className="row mb-sm-8">
              <div className="col text-center">
                  <img className="logo" width="asuto" height="auto" src={logo} alt="RecoveryTag" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <InvestForm
                  id={0}
                  initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    confirm: false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer py-4">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 offset-lg-2">
              <ul className="row">
                <li className="col-md-5 mb-2 mb-md-0">
                  <a
                    href="https://recoverylabs.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={marker} width={20} height={20} alt="Location" />
                    RECOVERYLABS
                  </a>
                </li>
                <li className="col-md-4 mb-2 mb-md-0">
                  <a href="mailto:hello@recoverylabs.io">
                    <img src={email} width={20} height={20} alt="Email" />
                    HELLO@RECOVERYLABS.IO
                  </a>
                </li>
                <li className="col-md-3">
                  <a href="/privacy">
                    <img
                      src={lock}
                      width={20}
                      height={20}
                      alt="Privacy Policy"
                    />
                    PRIVACY POLICY
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default IndexPage;
